<template>
  <div class="">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <div class="col-sm-12">
          <label class="typo__label">Intitule</label>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.intitule"
                @input="handleInput"
                type="text"
                class="form-control"
                id="intitule"
                name="intitule"
                placeholder="Intitule"
              />
              <label for="intitule">Intitule</label>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-12">
          <ValidationProvider rules="required|min:1" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.nombre"
                @input="handleInput"
                type="number"
                class="form-control"
                id="nombre"
                name="nombre"
                placeholder="nombre"
              />
              <label class="nombre">Nombre de postes</label>
              <!-- <label for="nombre">Intitule</label> -->
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-12" v-if="!isEntreprise">
          <label>Entreprise</label>
          <ValidationProvider rules="" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                v-uppercase
                :value="editableItem.entreprise"
                @input="handleInput"
                type="text"
                class="form-control"
                id="entreprise"
                name="entreprise"
                placeholder="Entreprise"
              />
              <label for="entreprise">Entreprise</label>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
            <!-- <multiselect
                v-model="editableItem.entreprise"
                :options="entreprises"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Entreprise"
                label="raisonSocial"
                track-by="raisonSocial"
                :preselect-first="false"
              /> 
              <span
                v-for="(err, index) in errors"
                :key="index"
                class="text-danger"
                >{{ err }}</span
              >
            </div>-->
          </ValidationProvider>
        </div>

        <div class="col-sm-12">
          <label class="typo__label">Métier</label>
          <div class="form-floating mb-3">
            <!-- <multiselect
              v-model="editableItem.poste"
              :options="romes"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Poste"
              label="libelle"
              track-by="libelle"
              :preselect-first="false"
            /> -->
            <metier-select v-model="editableItem.poste" :options="romes"> </metier-select>
          </div>
        </div>

        <div class="col-sm-12">
          <label class="typo__label">Domaine(s) d'étude(s)</label>

          <div class="form-floating mb-3">
            <multiselect
              v-model="editableItem.domaines"
              :options="domaines"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Domaine d'études"
              label="description"
              track-by="id"
              :preselect-first="false"
            />
          </div>
        </div>

        <div class="col-sm-12">
          <label for="descriptionTache">Nature</label>
          <ValidationProvider rules="" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <label class="typo__label">Nature</label>
              <multiselect
                v-model="editableItem.nature"
                :options="natures"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Natures"
                label="description"
                track-by="description"
                :preselect-first="false"
              />
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-12">
          <label for="descriptionTache">Niveau</label>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <label class="typo__label">Niveau d'etudes</label>
              <multiselect
                v-model="editableItem.niveaus"
                :options="niveaux"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Niveau d'études"
                label="description"
                track-by="description"
                :preselect-first="false"
              />
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <div class="form-floating mb-3">
              <input
                v-uppercase
                :value="editableItem.ageMin"
                @input="handleInput"
                type="number"
                class="form-control"
                id="ageMin"
                name="ageMin"
                placeholder="Age Minimun"
              />
              <label for="ageMin">Age Minimun</label>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-floating mb-3">
              <input
                v-uppercase
                :value="editableItem.ageMax"
                @input="handleInput"
                type="number"
                class="form-control"
                id="ageMax"
                name="ageMax"
                placeholder="Age Maximum"
              />
              <label for="ageMax">Age Maximum</label>
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <!-- <ValidationProvider rules="required" v-slot="{ errors }"> -->
          <label for="image">Image/PDF</label>

          <FilePondUploader
            ref="fileUploader"
            class="form-control col-12 md:col-12"
            @save="fileSaved($event)"
            :autoResize="true"
            rows="8"
          />
          <span v-if="invalidDocumentError" class="text-danger">
            Vous devez lier un fichier à cette offre
          </span>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <label>Date clôture</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <p-calendar
                  class="col-12 md:col-12"
                  :showIcon="true"
                  size="sm"
                  locale="fr"
                  name="dateCloture"
                  :manualInput="false"
                  v-model="editableItem.dateCloture"
                  :showWeek="true"
                />

                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <div class="form-floating mb-3">
              <input
                v-uppercase
                :value="editableItem.phone"
                @input="handleInput"
                type="number"
                class="form-control"
                id="phone"
                name="phone"
                placeholder="Téléphone"
              />
              <label for="phone">Téléphone</label>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-floating mb-3">
              <input
                v-uppercase
                :value="editableItem.email"
                @input="handleInput"
                type="number"
                class="form-control"
                id="email"
                name="email"
                placeholder="Email"
              />
              <label for="ageMax">Email</label>
            </div>
          </div>
        </div>

        <!-- <div class="row">
          <div class="col-sm-6">
            <label>Date debut publication</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <p-calendar
                  class="col-12 md:col-12"
                  :showIcon="true"
                  size="sm"
                  locale="fr"
                  name="dateDebutPub"
                  :manualInput="false"
                  v-model="editableItem.dateDebutPub"
                  :showWeek="true"
                />

                <span
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ err }}</span
                >
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-6">
            <label>Date fin publication</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <p-calendar
                  class="col-12 md:col-12"
                  :showIcon="true"
                  size="sm"
                  locale="fr"
                  name="dateFinPub"
                  :manualInput="false"
                  v-model="editableItem.dateFinPub"
                  :showWeek="true"
                />
                <span
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ err }}</span
                >
              </div>
            </ValidationProvider>
          </div>
        </div> -->
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { VueEditor } from "vue2-editor";
import Multiselect from "vue-multiselect";
// import AsyncMultiselect from "../../../AsyncMultiselect.vue";
import { authMixin } from "../../../../../mixins/auth-mixin";
import FilePondUploader from "../../../../uploader/FilePondUploaderOneFile.vue";
import MetierSelect from "../../../../common/MetierSelect.vue";

export default {
  props: ["value"],
  components: {
    VueEditor,
    Multiselect,
    MetierSelect,
    FilePondUploader,
  },
  mixins: [authMixin],
  data() {
    return {
      // hasNip: null,
      invalidDocumentError: false,
      //   editableItem: { ...this.value, nombre: this.nombre != null ? this.nombre : 1 },
      editableItem: { ...this.value },
      dateNais: this.$moment(this.value.dateNais).format("L"),
      heureHebo: [
        {
          libelle: "PLEIN",
        },
        {
          libelle: "PARTIEL",
        },
        {
          libelle: "SAISONNIER",
        },
        {
          libelle: "AUTRE",
        },
      ],
      niveauService: [
        {
          code: "SERVICE_DELEGUE",
          libelle: "SERVICE DELEGUE",
        },
        {
          code: "SERVICE_SEMI_DELEGUE",
          libelle: "SERVICE SEMI DELEGUE",
        },
        {
          code: "SERVICE_NON_DELEGUE",
          libelle: "SERVICE NON DELEGUE",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      domaines: "domaine/domaines",
      natures: "nature/natures",
      niveaux: "niveau/niveaux",
      typeOffres: "setting/typeOffres",
      pieces: "piece/typePieces",
      romes: "rome/romes",
      ethnies: "ethnie/ethnies",
      entreprises: "entreprise/entreprises",

      // formeJuridique: "setting/formeJuridique",
    }),
    offreId() {
      return this.$route.params.offreId;
    },
    isEntreprise() {
      return this.$userRole == "ROLE_ENTREPRISE";
    },
    maxDate() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      today.setFullYear(now.getFullYear() - 15);
      return today;
    },
  },
  created() {
    this.fetchDomaines();
    this.fetctNatures();
    this.fetchNiveaux();
    this.fetchRomes();
    if (!this.isEntreprise) {
      this.fetchEntreprises2();
    }

    if (this.offreId != null) {
      this.getOneOffre(this.offreId).then((data) => {
        this.editableItem = {
          ...this.editableItem,
          ...data,
          nombre: this.nombre != null ? this.nombre : 1,
        };
      });
    }
  },
  watch: {
    dateNais(val) {
      this.editableItem.dateNais = this.$moment(val).format("L");
      this.$emit("input", this.editableItem);
    },
    editableItem: {
      handler() {
        console.log(this.editableItem);
        this.$emit("input", this.editableItem);
      },
      deep: true,
    },
    "editableItem.hasNip": {
      handler() {
        if (this.editableItem.hasNip === false) {
          this.editableItem.npi = null;
        }
      },
    },
  },
  methods: {
    ...mapActions({
      fetchDomaines: "domaine/fetchDomaines",
      fetchNiveaux: "niveau/fetchNiveaux",
      fetctNatures: "nature/fetchNatures",
      fetchRomes: "rome/fetchRomes",
      getOneOffre: "offreExterne/getOneOffrePublished",
      fetchEntreprises2: "entreprise/fetchEntreprises2",
    }),
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      if (this.editableItem.urlImage == null) {
        this.$toast.error("Vous devez rattacher un ficher à cette offre.", {
          position: "bottom-right",
          duration: 8000,
        });
        this.invalidDocumentError = true;
        valid = false;
      }
      return valid;
    },
    fileSaved(files) {
      let imageUrl = this.editableItem.imageUrl;
      imageUrl = this.$refs.fileUploader.getFilesUrls();
      console.log(this.signature);
      this.invalidDocumentError = false;
      this.editableItem = {
        ...this.editableItem,
        urlImage: imageUrl,
      };
      // this.$emit("save", this.editableItem);
      // console.log(this.editableItem);
    },
  },
};
</script>

<style scoped></style>
-->
